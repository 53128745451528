import * as React from "react"



const tite = "The Majesty of Rosh Hashanah"

const IndexPage = () => {
	return (
		<main class="">

			<div class="flex flex-col justify-center items-center text-center box-border">
				<div class="rounded-xl md:rounded-2xl lg:rounded-4xl shadow-md bg-gray-200 m-2 min-w-[95%] bg-gradient-to-bl from-gray-100 via-yellow-100 to-gray-100 bg-no-repeat bg-cover">
					<h1 class="text-2xl md:text-4xl lg:text-6xl xl:text-7xl 2xl:text-8xl m-3">
						{tite}

					</h1>
				</div>
				<div class="rounded-l md:rounded-xl lg:rounded-2xl shadow-md bg-gray-200 m-2 w-[95%] bg-gradient-to-bl from-gray-100 via-yellow-200 to-gray-100 bg-no-repeat bg-cover">

					<h2 class="text-xl md:text-2xl lg:text-4xl xl:text-5xl 2xl:text-6xl py-2 my-2">About this book</h2>
					<p class="prose-base md:prose-lg lg:prose-xl m-2">Discovering the true meaning of
						prayer is a journey. Superficially, one
						thinks of prayer as merely a means of
						asking the Divine for one’s needs and
						desires. As one delves deeper, he discovers
						that he himself is involved in
						“Divine Service” in a way that can in
						fact be compared to the Temple of Old.
						As the journey continues, one discovers
						that our prayer book is a great treasure
						of “privileged information,” revealing
						the greatest secrets not just about
						man and his destiny but also about the
						Creator’s relationship with mankind in
						general and with His beloved people in
						particular… Nowhere is this more apparent
						than in the prayers of the Musaf
						of Rosh Hashanah, which are built
						exclusively on many verses from all
						over the Torah, Prophets (Nevi’im) and
						Writings (Kesuvim).
						We are ever so fortunate to have available
						the greatly researched work of my
						dear friend, Rabbi Shimon Mordechai
						Cohen, who has prepared the way for
						us to continue our journey, learning
						and living each verse in its full meaning
						and beauty. I hope all the readers
						will enjoy this work as much as I did!<br />
						Rav Yehudah Leib Wittler, shlita
					</p>
				</div>

				<div class="rounded-l md:rounded-xl lg:rounded-2xl shadow-md bg-gray-200 m-2 w-[95%]  bg-gradient-to-bl from-gray-100 via-yellow-200 to-gray-100 bg-no-repeat bg-cover ">
					<h3 class="text-l md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl py-1 my-1">buy now</h3>
					<div class="flex flex-col lg:flex-row">
						<a href="https://www.blurb.com/b/11230022-the-majesty-of-rosh-hashanah" class="block border border-blue rounded-full p-3  bg-gray-500 text-white text-l flex-1 m-2 md:m-3 lg:m-4 bg-gradient-to-bl from-gray-500 via-yellow-500 to-gray-500 bg-no-repeat bg-cover ">
							<h4>USA 🇺🇸</h4>
							<p>buy here</p>
						</a>
						<a href="https://www.blurb.co.uk/books/11230022-the-majesty-of-rosh-hashanah" class="block border border-blue rounded-full p-3  bg-gray-500 text-white text-l flex-1 m-2 md:m-3 lg:m-4 bg-gradient-to-bl from-gray-500 via-yellow-500 to-gray-500 bg-no-repeat bg-cover ">
							<h4>UK 🇬🇧</h4>
							<p>buy here</p>
						</a>
					</div>

				</div>

				<div class="rounded-xl md:rounded-2xl lg:rounded-4xl shadow-md bg-gray-200 m-2 w-[95%] bg-gradient-to-bl from-gray-100 via-yellow-100 to-gray-100 bg-no-repeat bg-cover">
					<h3 class="text-l md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl py-1 my-1">Preview</h3>
		<a class="block border border-blue rounded-full p-3  bg-gray-500 text-white text-l flex-1 m-2 md:m-3 lg:m-4 bg-gradient-to-bl from-gray-400 via-yellow-400 to-gray-400 bg-no-repeat bg-cover" href="newpdf.pdf" target="_blank">Click here to download a free sample PDF</a>
				</div>


			</div>

		</main >
	)
}

export default IndexPage

export const Head = () => <title>{tite}</title>
